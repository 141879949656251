"use client"
import styled from "@emotion/styled"
import { Button, IconButton } from "@mui/material"
import Link from "next/link"
import { useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import {
  Desktop,
  Mobile,
  NewText,
  transitionDelayTime,
  TransitionUp,
  useInViewConfig,
} from "ui"
import VideoMuteIcon from "ui/icons/video/VideoMuteIcon"
import VideoPauseIcon from "ui/icons/video/VideoPauseIcon"
import VideoPlayIcon from "ui/icons/video/VideoPlayIcon"
import VideoUnmuteIcon from "ui/icons/video/VideoUnmuteIcon"
import ImageWithAlt from "ui/ImageWithAlt"
import { extractImage, extractProperty } from "utils"

const StyledContainer = styled.div`
  position: relative;
  height: 680px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 520px;
  }
`

const StyledTextContainer = styled.div`
  width: calc(100vw - 32px);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  padding: 24px;
  border-radius: 12px;
  border: 3.5px solid #0d0630;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0.88) 1.08%,
    rgba(255, 255, 255, 0) 147.87%,
    rgba(255, 255, 255, 0) 147.87%
  );
  backdrop-filter: blur(25.5px);
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 40px;
    width: 580px;
    left: calc((100vw - 1080px) / 2);
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 40px;
    width: 580px;
    left: calc((100vw - 1360px) / 2);
  }
`

const StyledButtonContainer = styled.div`
  margin-top: 40px;
`

const StyledButton = styled(Button)`
  width: 100%;
  :hover {
    background: rgba(24, 136, 226, 1);
    text-decoration: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
  }
`

const StyledVideoContainer = styled.div`
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`
const StyledVideo = styled.video`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledVideoButtonContainer = styled.div`
  position: absolute;
  bottom: 70px;
  right: 16px;
  z-index: 3;
  button {
    padding: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 0px;
    right: 16px;
  }
`

type Props = {
  data: any
}

function HomeHeader({ data }: Props) {
  const title = extractProperty(data, "headerTitle")
  const subtitle = extractProperty(data, "headerSubtitle")
  const learnMore = extractProperty(data, "learnMoreButton")
  const desktopImage = extractImage(data, "heroVideoDesktop")
  const mobileImage = extractImage(data, "heroVideoMobile")

  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const mobileRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
    if (mobileRef.current) {
      if (isPlaying) {
        mobileRef.current.pause()
        setIsPlaying(false)
      } else {
        mobileRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      if (isMuted) {
        videoRef.current.muted = false
        setIsMuted(false)
      } else {
        videoRef.current.muted = true
        setIsMuted(true)
      }
    }
    if (mobileRef.current) {
      if (isMuted) {
        mobileRef.current.muted = false
        setIsMuted(false)
      } else {
        mobileRef.current.muted = true
        setIsMuted(true)
      }
    }
  }
  const { ref, inView } = useInView(useInViewConfig)

  return (
    <StyledContainer ref={ref}>
      <Desktop>
        <StyledVideoContainer>
          {desktopImage?.url?.endsWith("mp4") ? (
            <>
              <StyledVideo
                preload="auto"
                autoPlay
                loop
                muted
                playsInline
                ref={videoRef}
                onLoadedData={() => videoRef.current?.play()}
              >
                <source src={desktopImage?.url} type="video/mp4" />
              </StyledVideo>
            </>
          ) : (
            <>
              <ImageWithAlt image={desktopImage} fill objectFit="cover" />
            </>
          )}
          {desktopImage?.url.endsWith("mp4") && (
            <StyledVideoButtonContainer>
              <IconButton
                aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                onClick={() => handlePlay()}
                disableRipple
              >
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </IconButton>
              <IconButton
                aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
                onClick={() => handleMute()}
                disableRipple
              >
                {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
              </IconButton>
            </StyledVideoButtonContainer>
          )}
        </StyledVideoContainer>
      </Desktop>
      <Mobile>
        <StyledVideoContainer>
          {mobileImage?.url?.endsWith("mp4") ? (
            <>
              <StyledVideo
                preload="auto"
                autoPlay
                loop
                muted
                playsInline
                ref={videoRef}
                onLoadedData={() => videoRef.current?.play()}
              >
                <source src={mobileImage?.url} type="video/mp4" />
              </StyledVideo>
            </>
          ) : (
            <>
              <ImageWithAlt image={mobileImage} fill objectFit="cover" />
            </>
          )}
          {mobileImage?.url.endsWith("mp4") && (
            <StyledVideoButtonContainer>
              <IconButton
                aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                onClick={() => handlePlay()}
                disableRipple
              >
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </IconButton>
              <IconButton
                aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
                onClick={() => handleMute()}
                disableRipple
              >
                {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
              </IconButton>
            </StyledVideoButtonContainer>
          )}
        </StyledVideoContainer>
      </Mobile>

      <StyledTextContainer>
        <TransitionUp $inView={inView} $delay={transitionDelayTime * 0.5}>
          <NewText
            component={"h1"}
            desktopFontSize={48}
            desktopLineHeight={"48px"}
            desktopFontWeight={600}
            mobileFontSize={40}
            mobileLineHeight={"46px"}
            mobileFontWeight={600}
            color={colors.black}
          >
            {title}
          </NewText>
          <NewText
            marginTop="16px"
            component={"p"}
            desktopFontSize={20}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={16}
            mobileLineHeight={"16px"}
            color={colors.black}
          >
            {subtitle}
          </NewText>

          {learnMore?.[0] && (
            <StyledButtonContainer>
              <Link href={learnMore[0]?.url}>
                <StyledButton
                  style={{ textTransform: "none" }}
                  variant="contained"
                  size="large"
                >
                  {learnMore[0]?.name}
                </StyledButton>
              </Link>
            </StyledButtonContainer>
          )}
        </TransitionUp>
      </StyledTextContainer>
    </StyledContainer>
  )
}

export default HomeHeader
